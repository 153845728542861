<template>
  <div>
    <div class="w100 text-center">
      <span class="results-title"><strong>{{ resultsTabTitle }}</strong></span>
    </div>
         
    <!-- Overall Score / Advice -->
    <b-alert v-if="errorMessage" variant="danger" show class="text-center p-1 mt-2">
      {{ errorMessage }}
    </b-alert>
    <div v-else-if="resultsLoading" class="text-center p-2">
      <b-spinner label="Loading..."></b-spinner>
    </div>
    <div v-else class="text-center p-2">
      <h3 class="results-content-title" >Overall Score: <b-badge :variant="getBadgeVariant(scores.overall)">{{ scores.overall.toFixed(2) }}</b-badge></h3>
      <h5 class="" ><b>{{questionnaireTypes[category].type1}}:</b> <b-badge :variant="`light-${getBadgeVariant(scores.type1)}`">{{ scores.type1.toFixed(2) }}</b-badge> - <b>{{questionnaireTypes[category].type2}}:</b> <b-badge :variant="`light-${getBadgeVariant(scores.type2)}`">{{ scores.type2.toFixed(2) }}</b-badge></h5>
      <h5 class="mt-2 mb-0 pb-0">~ {{ overallAdvice }}</h5>
    </div>
        
    <!-- Comments by Competency -->
    <app-collapse v-if="!errorMessage && !resultsLoading">
      <!-- General Comments Section -->
      <b-card class="mb-1" header="General" header-tag="header" footer-tag="footer" border-variant="primary">
        <b-card-body>
          <!-- General Comments -->
          <comments-collapse-item-refresh 
            v-if="generalComments['General Comment'] && generalComments['General Comment'].length" 
            title="General Comment"
            @refresh="fetchUserResults">
            <comments-cards :comments="generalComments['General Comment']" />
          </comments-collapse-item-refresh>

          <!-- Top Skills and Tips -->
          <comments-collapse-item-refresh 
            v-if="generalComments['Top Skills and Tips on strengthening them'] && generalComments['Top Skills and Tips on strengthening them'].length" 
            title="Top Skills and Tips on strengthening them"
            @refresh="fetchUserResults">
            <comments-cards :comments="generalComments['Top Skills and Tips on strengthening them']" />
          </comments-collapse-item-refresh>

          <!-- Top Detractors and Tips -->
          <comments-collapse-item-refresh 
            v-if="generalComments['Top Detractors and Tips on minimizing them'] && generalComments['Top Detractors and Tips on minimizing them'].length" 
            title="Top Detractors and Tips on minimizing them"
            @refresh="fetchUserResults">
            <comments-cards :comments="generalComments['Top Detractors and Tips on minimizing them']" />
          </comments-collapse-item-refresh>
        </b-card-body>
      </b-card>

      <!-- Competency Comments -->
      <b-card class="" header="Competencies" header-tag="header" footer-tag="footer" border-variant="primary">
        <b-card-body>   
          <comments-collapse-item-refresh 
              v-for="(comments, competency) in allCompetencies" 
              :key="competency"
              :title="competency"
              :competencyScores="getCompetencyScores(competency)"
              @refresh="fetchUserResults">
              <comments-cards :comments="comments" />
            </comments-collapse-item-refresh>
          </b-card-body>
        </b-card>
    </app-collapse>
  </div>
</template>

<script>
import { BBadge, BAlert, BSpinner, BCard, BCardBody } from 'bootstrap-vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import CommentsCollapseItemRefresh from '../../components/collapse/CommentsCollapseItemRefresh.vue'
import CommentsCards from '../../components/cards/CommentsCards.vue'
import useAppConfig from '@core/app-config/useAppConfig'
import { getSurveyTypeFromTitle } from '../../utils'

export default {
  components: {
    AppCollapse,
    CommentsCollapseItemRefresh,
    CommentsCards,
    BBadge,
    BAlert,
    BSpinner,
    BCard,
    BCardBody,
  },
  setup() {
    const { isNavMenuHidden } = useAppConfig()
    return {
      isNavMenuHidden
    }
  },
  beforeCreate() {
    this.$http.get('/questionnaires/types')
      .then(response => {
        this.questionnaireTypes = response.data;
      })
      .catch(error => console.log(error))
  },
  created() {
    this.fetchUserResults()
  },
  data() {
    return {
      loading: true,
      scores: {
        overall: 0,
        type1: 0,
        type2: 0,
        competency: {},
      }, 
      overallAdvice: '',
      comments: [],
      questionnaireTypes: {},
      competencyComments: {},
      generalComments: [],
      errorMessage: '',
      resultsLoading: false,
      category: '',
      fullName: '',
    }
  },
  computed: {
    resultsTabTitle() {
      return this.fullName ? `${this.fullName}'s Results` : 'Results'
    },
    allCompetencies() {
      const competencies = { ...this.competencyComments };
      return competencies;
    },
  },
  methods: {
    getSurveyTypeFromTitle,
    getBadgeVariant(score) {
      if (score > 2) return 'success';
      if (score > 1) return 'warning';
      if (score >= 0) return 'danger';
      return 'primary';
    },
    getCompetencyScores(competency) {
      
      const type1Name = this.questionnaireTypes[this.category].type1
      const type2Name = this.questionnaireTypes[this.category].type2
      const type1Score = this.scores.competency[competency].type1
      const type2Score = this.scores.competency[competency].type2

      const res = {}
      res[type1Name] = type1Score
      res[type2Name] = type2Score
      return res
    },
    fetchUserResults() {
      const userId = this.$route.params.id
      this.errorMessage = ''
      this.resultsLoading = true
      this.$http.get(`/results/myResults/${userId}`)
        .then(response => {
          this.scores = response.data.scores;
          this.overallAdvice = response.data.overallAdvice;
          this.competencyComments = response.data.competencies;
          this.generalComments = response.data.general || {
            "General Comment": [],
            "Top Skills and Tips on strengthening them": [],
            "Top Detractors and Tips on minimizing them": []
          };
          this.category = response.data.category;
          this.resultsLoading = false
          this.fullName = response.data.fullName
          this.$emit('results-fetched')
        })
        .catch(error => {
          this.resultsLoading = false
          if (error.response) {
            this.fullName = error.response.data.fullName
            this.errorMessage = (error.response && error.response.data && error.response.data.error) || error || 'No results to display.'
          } else {
            console.log(error)
          }
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-sweetalert.scss';

.results-header {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
}

.results-title {
  font-size: 1.5rem;
  color: #007bff;
  padding: 0.5rem 1rem;
  border: 2px solid #007bff;
  border-radius: 0.25rem;
  border-color: #007bff;
  font-weight: bold;
  font-size: 1.5rem;
}

.results-content-title {
  font-size: 1.5rem;
  color: #007bff;
}

</style>